<template>
  <div class="animated fadeIn">
    <b-container>
      <b-row class="mt-5">
        <b-col>
          <b-card>
            <b-button
              block
              variant="primary"
              @click="start()"
              size="lg"
              v-if="!is_start"
              :disabled="is_run"
              >start {{ current_at.format("YYYY-MM-DD") }}</b-button
            >
            <b-button block variant="danger" @click="stop()" size="lg" v-else
              >stop</b-button
            >
            <p v-if="is_run" class="mb-0 mt-3">
              {{ current_at.format("YYYY-MM-DD") }} тооцоолж байна ...
            </p>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-card
            title="Амжилттай"
            border-variant="primary"
            text-variant="primary"
          >
            <ul class="list-group">
              <li
                class="list-group-item"
                :key="index"
                v-for="(item, index) in daily_report.data.success"
              >
                {{ index + 1 }}. {{ item }}
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card
            title="Алдаа гарсан"
            border-variant="danger"
            text-variant="danger"
          >
            <ul class="list-group">
              <li
                class="list-group-item"
                :key="index"
                v-for="(item, index) in daily_report.data.fail"
              >
                {{ index + 1 }}.{{ item }}
                <b-button
                  variant="primary"
                  @click="one_calc(index)"
                  class="pull-right"
                  >restart
                </b-button>
                <p v-if="restart_list[index]">уншиж байна ...</p>
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "old-data",
  data: function() {
    return {
      isLoading: false,
      current_at: moment("2019-01-01"),
      end: moment().subtract(1, "days"),
      daily_report: {
        data: {
          success: [],
          fail: [],
        },
        last_date: null,
      },
      is_start: false,
      is_run: false,
      restart_list: [],
    };
  },
  created() {
    if (
      this.$store.state.daily_report != undefined &&
      this.$store.state.daily_report.data != undefined
    ) {
      this.$data.daily_report = this.$store.state.daily_report;
      if (this.$data.daily_report.last_date != null) {
        this.$data.current_at = moment(this.$data.daily_report.last_date);
      }
    }
    if (this.$data.daily_report.last_date == null) this.$data.is_start = false;
  },
  methods: {
    start: function() {
      this.$data.is_start = true;
      this.run();
    },
    run: function() {
      this.$data.is_run = true;
      this.$http
        .get(this.$config.API_URL + "ReportWebService/test", {
          params: { date: this.$data.current_at.format("YYYY-MM-DD") },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            this.$data.is_run = false;
            //console.log(response)
            if (response.body.status == 1) {
              this.$data.daily_report.data.success.push(
                this.$data.current_at.format("YYYY-MM-DD")
              );
            } else {
              this.$data.daily_report.data.fail.push(
                this.$data.current_at.format("YYYY-MM-DD")
              );
              this.$data.restart_list.push(false);
            }
            this.$data.current_at.add(1, "days");
            this.$data.daily_report.last_date = this.$data.current_at.format(
              "YYYY-MM-DD"
            );
            this.$store.commit("ADD_STATE", {
              key: "daily_report",
              value: this.$data.daily_report,
            });
            if (
              this.$data.is_start &&
              this.$data.current_at <= this.$data.end
            ) {
              this.run();
            }
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    one_calc: function(index) {
      this.$data.restart_list[index] = true;
      this.$http
        .get(this.$config.API_URL + "ReportWebService/test", {
          params: { date: this.$data.daily_report.data.fail[index] },
          headers: this.$store.getters.httpHeader,
          emulateJSON: true,
        })
        .then(
          (response) => {
            //console.log(response)
            this.$data.restart_list[index] = false;
            if (response.body.status == 1) {
              this.$data.daily_report.data.success.push(
                this.$data.daily_report.data.fail[index]
              );
              this.$data.daily_report.data.fail.splice(index, 1);
              this.$data.restart_list.splice(index, 1);
            }
            this.$store.commit("ADD_STATE", {
              key: "daily_report",
              value: this.$data.daily_report,
            });
          },
          (response) => {
            this.isLoading = false;
          }
        );
    },
    stop: function() {
      this.$data.is_start = false;
    },
  },
};
</script>
